<template>
  <div class="payment-tabs">
    <b-tabs>
      <b-tab active>
        <template #title>
          <b-button variant="light-blue">
            <EditIcon class="" /><span class="ml-2 mr-3">Pay with Card</span>
          </b-button>
        </template>
        <PaymentForm class="card profile-information m-rounded-12 pt-2 pb-5 px-3" />
      </b-tab>

      <b-tab>
        <template #title>
          <b-button variant="light">
            <EditIcon variant="light" class="" /><span class="ml-2 mr-3">
              Pay with Deel account
            </span>
          </b-button>
        </template>
        <p class="p-3">Tab contents 2</p>
      </b-tab>

      <b-tab>
        <template #title>
          <b-button variant="light">
            <EditIcon variant="light" class="" />
            <span class="ml-2 mr-3"> Pay with Wise account </span>
          </b-button>
        </template>
        <p class="p-3">Tab contents 2</p>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import EditIcon from "../svg/EditIcon.vue";
import PaymentForm from "./PaymentForm.vue";
export default {
  components: {
    EditIcon,
    PaymentForm,
  },
};
</script>

<style lang="scss" scoped>
.payment-tabs {
  .card {
    width: 79%;
    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }
  .btn {
    height: 38px;
    @include media-breakpoint-down(sm) {
      span {
        display: none;
      }
    }
  }
  .btn-light-blue {
    color: $primary-color;
    background-color: rgb(8, 86, 157, 0.1);
  }
  .btn-light {
    color: $grey-tint-6;
    background-color: $grey-tint-4;
    border: 1px solid $grey-tint-5;
  }
}
</style>
