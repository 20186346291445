<template>
  <div class="settings">
    <div class="dashboard-content py-4">
      <div
        class="settings__container p-4 animate__animated animate__lightSpeedInLeft animate__delay-1s"
      >
        <div class="payment-header d-flex flex-row justify-content-between">
          <h2>Pick a payment method</h2>
          <b-button variant="blue" class="mr-5" @click="goBack">
            <ArrowLeftIcon class="arrow mr-2" />Back
          </b-button>
        </div>
        <hr class="horizontal_rule mb-4" />
        <PaymentTabs />
      </div>
    </div>
  </div>
</template>

<script>
import ArrowLeftIcon from "../../components/svg/ArrowLeftIcon.vue";
import PaymentTabs from "../../components/settings/PaymentTabs.vue";
import "animate.css";
export default {
  components: {
    ArrowLeftIcon,
    PaymentTabs,
  },
  data() {
    return {
      user: {},
    };
  },
  methods: {
    goBack() {
      this.$router.replace("/dashboard/settings");
    },
  },
};
</script>
<style lang="scss" scoped>
.settings {
  h2 {
    color: $black-tint-3;
    font-size: 1.5rem;
    line-height: 2.625rem;
    font-weight: 600;
  }
  &__container {
    background-color: $body-bg;
  }
  .btn-blue {
    color: $primary-color;
  }
}
</style>
