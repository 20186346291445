<template>
  <div class="payment-form">
    <b-form ref="editPaymentMethod" class="payment-form_form mr-5 mt-3 mb-3">
      <b-form-group label="Card Number" label-for="number">
        <b-form-input
          id="card-no"
          class="card-number p-fs-14"
          placeholder="**** **** **** ****"
          maxlength="16"
        >
          <img src="../../assets/img/master-card.png" alt="master-card" />
        </b-form-input>
      </b-form-group>
      <div class="row justify-content-left">
        <b-form-group label="Expiration Date" label-for="number" class="col">
          <b-form-input
            id="expiry"
            class="expiration-date p-fs-14"
            placeholder="mm / yy"
            maxlength="5"
          ></b-form-input>
        </b-form-group>
        <b-form-group label="Security Code" label-for="number" class="col">
          <b-form-input
            id="cvv"
            class="security-code p-fs-14"
            placeholder="***"
            maxlength="3"
          ></b-form-input>
        </b-form-group>
      </div>
      <b-button block type="submit" variant="primary" class="mt-3 mb-5">Submit</b-button>
    </b-form>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.payment-form {
  &_input {
    color: #afb9c6;
    border: #afb9c6;
  }
}
</style>
